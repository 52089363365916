.ql-bubble {
  border: 1px solid #303030;
  border-radius: 8px;
}

.ql-editor ol li::before {
  content: none!important;
}

.ant-segmented-group {
  display: inline-flex;
  flex-wrap: wrap;
  flex-direction: row;
}

.ql-container.ql-snow,
.ql-container.ql-bubble {
  border: 0px solid #303030;
  border-radius: 8px;
  /* overflow-y: auto;
    max-height: 1000px; */
}

.ql-toolbar.ql-snow {
  border: 0px solid #303030;
  border-radius: 8px;
}

.ql-editor p {
  margin: 12px 0 !important;
  font-family: "Inter";
}

.ql-editor {
  line-height: 1.5 !important;
}

.ql-editor li {
  margin: 12px 0 !important;
  font-family: "Inter";
}

.ql-editor h1,
h2,
h3,
h4,
h5 {
  font-family: "Inter";
}

.ql-container {
  font-size: 16px !important;
}

table.quill-better-table td {
  border: 1px solid #444;
}
.ql-clipboard {
  position: fixed;
  display: none;
  left: 50%;
  top: 50%;
}

table.quill-better-table td {
  border: 1px solid #444;
}

.ql-clipboard {
  position: fixed;
  display: none;
  left: 50%;
  top: 50%;
}