.signImage {
  display: flex !important;
  justify-content: center;
  align-content: center;
  height: 204px;
}
.signImage > img {
  height: 200px;
}



