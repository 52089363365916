.btngrad1 {
    background-image: linear-gradient(to right, #00d2ff 0%, #3a7bd5  51%, #00d2ff  100%)!important;
    transition: 0.5s!important;
    background-size: 200% auto!important;
    box-shadow: 0 0 20px #eee!important;
    border-radius: 10px!important;
  }
  
  .btngrad1:hover {
    background-position: right center!important; /* change the direction of the change here */
    color: #fff!important;
    text-decoration: none!important;
  }

  .btngrad2 {
    background-image: linear-gradient(to right, #02AAB0 0%, #00CDAC  51%, #02AAB0  100%)!important;
    transition: 0.5s!important;
    background-size: 200% auto!important;
    box-shadow: 0 0 20px #eee!important;
    border-radius: 10px!important;
  }
  
  .btngrad2:hover {
    background-position: right center!important; /* change the direction of the change here */
    color: #fff!important;
    text-decoration: none!important;
  }

  .btngrad3 {
    background-image: linear-gradient(to right, #E55D87 0%, #5FC3E4  51%, #E55D87  100%)!important;
    transition: 0.5s!important;
    background-size: 200% auto!important;
    box-shadow: 0 0 20px #eee!important;
    border-radius: 10px!important;
  }
  
  .btngrad3:hover {
    background-position: right center!important; /* change the direction of the change here */
    color: #fff!important;
    text-decoration: none!important;
  }

 