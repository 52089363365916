body {
  margin: 0;
  padding: 0;
}

.ant-segmented-group {
  display: inline-flex;
  flex-wrap: wrap;
  flex-direction: row;
}

:where(.css-dev-only-do-not-override-1pqzx8z).ant-carousel
  .slick-dots
  li
  button {
  background: blue !important;
  opacity: 0.6;
  height: 4px;
}

.ql-smart-field {
  border-radius: 4px;
  background-color: rgba(0, 97, 0, 0);
}

iframe#webpack-dev-server-client-overlay {
  display: none !important;
}

table.quill-better-table td {
  border: 1px solid #444;
}
.ql-clipboard {
  position: fixed;
  display: none;
  left: 50%;
  top: 50%;
}

.cl-modalContent,
.cl-userButtonPopoverCard {
  font-family: 'Inter';
}

.cl-avatarBox {
  height: 28px;
  width: 28px;
  background: linear-gradient(#000, #000) padding-box,
    linear-gradient(42deg, #000 45%, rgba(255, 255, 255, 0.6)) border-box;
  border: 1px solid transparent;
}
