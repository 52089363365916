code {
  font-family: 'Inconsolata', monospace !important;
  white-space: break-spaces !important;
}

/* pre {
  background: black !important;
  border-radius: 8px;
} */

span.comment.linenumber.react-syntax-highlighter-line-number {
  color: #666!important;
}

@media only screen and (max-width: 716px) {
  iframe {
    display: none;
  }
}